class CustomInfo extends window.gantt.views["task/info"] {
    config() {
      this.Compact = this.getParam("compact", true);
      let ctx = this;
      const ui = super.config();
      if (!this.Compact) {
        //styling
        ui.body.rows[0].padding = 4;
        ui.body.rows[1].padding = 4;
        ui.body.rows[1].rows[0].autoheight = true;
        ui.body.rows[1].rows[1].inputWidth = 0;
      }
      ui.body.rows[0].elements[0].click = function(){
        // Close popup on x button
        ctx.ClosePopup();
      };
      return ui;
    }
    InfoTemplate(obj) {
      //obj.text = "Debug - " + obj.text;
      //less details
      obj.targets = obj.sources = [];
      delete obj.details;
      return super.InfoTemplate(obj);
    }
    ClosePopup(){
      this.getParentView().Hide();
    }
    EditTask() {
      if (!this.Compact) {
        this.getParentView().Hide();
        this.app.show("top/task.form");
      } else super.EditTask();
    }
  }

  class InfoPopup extends window.gantt.views.JetView {
    config() {
      return {
        view: "popup",
        width: 350,
        body: CustomInfo,
      };
    }
    Show(node) {
      this.getRoot().show(node);
    }
    Hide() {
      this.getRoot().hide();
    }
  }

  class CustomForm extends window.gantt.views["task/form"] {
    config() {
      let ui = super.config();
      if (!this.Compact) {
        //textarea
        const notes = ui.body.rows[1].elements.splice(6, 1)[0];
        notes.height = 290;

        //form with 3 columns
        const form = ui.body.rows[1];
        form.cols = [
          { margin: form.margin, rows: ui.body.rows[1].elements },
          { rows: [notes, {}] },
          { width: 300 },
        ];
        form.margin = 20;
        delete ui.body.rows[1].elements;
        ui.body.rows[1] = form;

        //bar
        ui.body.rows[0].padding.right = 335;
      }
      return ui;
    }
    // eslint-disable-next-line
    Back(exit) {
      if (!this.Compact) {
        this.Form.clear();
        this.State.selected = this.State.parent = null;
        this.app.show("top/chart");
      } else super.Back(close);
    }
    Done(exit) {
      if (this.Form.isDirty())
        this.UpdateTask().then(() => this.Back(exit));
      else this.Back(exit);
    }
    Close() {
      if (this.Form.isDirty()) {
        window.webix
          .confirm({
          text: "Save changes?",
        })
          .then(() => this.Done(true))
          .catch(() => this.Back(true));
      } else this.Back(true);
    }
  }
  class CustomBarsChart extends window.gantt.views["chart"] {
    config() {
      const ui = super.config();
      ui.scroll = 'x';
      return ui;
    }
  }

  class CustomBarsView extends window.gantt.views["chart/bars"] {
    config() {
      const ui = super.config();
      ui.id = 'myGanttBars'
      // eslint-disable-next-line
      ui.cells[2].on.onItemClick = (id, e, node) => {
        this.State.$batch({
          parent: null,
          selected: id,
        });
        this.Info.Show(node);
      };
      ui.cells[2].scroll = 'x'

      // remove tooltips
      ui.cells[2].tooltip = false;
      ui.scroll = 'x';
      return ui;
    }
    init(view) {
      super.init(view);
      //create a popup window
      this.Info = this.ui(InfoPopup);
      //handle hide event
      this.on(this.app, "edit:stop", () => {
        this.State.selected = this.State.parent = null;
        this.Info.Hide();
      });
    }
  }

  class CustomTopView extends window.gantt.views.top {
    config() {
      const ui = super.config();
      // insert dynamic subview to show chart or form
      ui.cells[0].cols[2] = { $subview: true };
      return ui;
    }
    init(view) {
      super.init(view);
      //show chart
      this.show("chart/bars");
      //create a popup window
      this.Info = this.ui(InfoPopup);
    }

    ShowTask(path) {
      if (this.Compact) super.ShowTask(path);
      else if (path === "form")
        //new task
        this.show("task.form");
    }
    HideTask() {
      if (this.Compact);
      super.HideTask();
    }
  }

export {
    CustomBarsChart,
    CustomTopView,
    CustomBarsView,
    CustomForm,
    CustomInfo
}